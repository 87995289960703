<template>
  <v-container fluid style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'customers'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1>{{ customer.first_name }} {{ customer.last_name }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="customerCreate"
        >
          {{ $t('customer.buttons.save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      preloader: true,
      customer: {}
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.$store.dispatch('customerEdit', id).then(() => {
      this.customer = this.$store.getters.customer
      this.preloader = false
    })
  },
}
</script>